<template></template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { methods } from "vue-echarts";
import axios from "axios";

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
 
  created() {
    //  alert(0);
    //  this.$router.push("/dashboards")
    //  this.$router.push("/classes/subjects/topics")
    window.location = '/#/dashboard'
    //  this.$router.push("dashboards")
  },
};
</script>

<style>
</style>
